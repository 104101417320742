import React from "react"
import github from "../images/github.svg"
import gitlab from "../images/gitlab-orange.svg"
import jetbrains from "../images/intellij.svg"
import vscode from "../images/vscode.svg"

const IntegrationLogos = () => {
  return (
    <div className="flex flex-row justify-center items-center gap-8 md:gap-12 px-4 flex-wrap">
      <a
        href="https://app.sourcery.ai/"
        className="hover:opacity-80 transition-opacity"
      >
        <img src={vscode} alt="VS Code" className="h-8 w-8 md:h-10 md:w-10" />
      </a>
      <a
        href="https://app.sourcery.ai/"
        className="hover:opacity-80 transition-opacity"
      >
        <img
          src={jetbrains}
          alt="JetBrains"
          className="h-8 w-8 md:h-10 md:w-10"
        />
      </a>
      <a
        href="https://app.sourcery.ai/"
        className="hover:opacity-80 transition-opacity"
      >
        <img src={github} alt="GitHub" className="h-8 w-8 md:h-10 md:w-10" />
      </a>
      <a
        href="https://app.sourcery.ai/"
        className="hover:opacity-80 transition-opacity"
      >
        <img src={gitlab} alt="GitLab" className="h-8 w-8 md:h-10 md:w-10" />
      </a>
    </div>
  )
}

export default IntegrationLogos
